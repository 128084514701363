import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/food-permit-license-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const FPLU = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>Get Your USA Food Permit License with SBA Tax Consultants</title>
        <meta name="description" content="Need a food permit license in the USA? SBA Tax Consultants can help with applications for food business and vendor permits. Contact us now!" />
        <meta name="keywords" content="Food permit license USA, Food business permit USA, USA food license application, Food vendor permit USA, USA food handler permit" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Food Permit License USA</strong></h1>
                <p className='text-white py-3 ppp'>Get your USA food permit license hassle-free with expert guidance from SBA Tax Consultants!</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Get Your Food Permit License USA with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Secure your food permit license in the USA effortlessly with SBA Tax Consultants. Our expert team assists with food business permits, USA food license applications, food vendor permits, and food handler permits.</p>
            <p> Trust us to streamline your licensing process for a hassle-free experience.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default FPLU;