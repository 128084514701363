import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/Occupancy-Certificate-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const OCUR = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>Occupancy Certificate USA: Requirements & Application | SBA Tax</title>
        <meta name="description" content="Get your Occupancy Certificate for new buildings in the USA. Learn requirements, application process, and more with SBA Tax Consultants." />
        <meta name="keywords" content="Occupancy Certificate USA Requirements, How to Obtain an Occupancy Certificate USA, Occupancy Certificate Application USA, Occupancy Certificate for New Buildings USA, Occupancy Certificate USA Process" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Occupancy Certificate USA Requirements</strong></h1>
                <p className='text-white py-3 ppp'>Need an occupancy certificate in the USA? SBA Tax Consultants can guide you through the requirements and help with the process. Contact us for expert assistance and ensure compliance</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Your Guide to Obtaining an Occupancy Certificate in the USA</b></h2>
            <p>Navigating the Occupancy Certificate USA Requirements can be complex, but with SBA Tax Consultants, it's straightforward. Learn how to obtain an occupancy certificate in the USA with our expert guidance. Whether you need an Occupancy Certificate for New Buildings in the USA or assistance with the Occupancy Certificate Application USA, our team is here to streamline the occupancy certificate USA process for you.</p>            
            <p>Trust us to handle the details and ensure compliance, so you can focus on what matters most.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default OCUR;