import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/Health-Permit-in-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const HPBU = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>Health Permit Requirements USA | SBA Tax Consultants</title>
        <meta name="description" content="Get expert help with USA health permit applications and regulations. SBA Tax Consultants streamline your health permit processing." />
        <meta name="keywords" content="Health Permit Requirements USA, USA Health Permit Application, Health Permit for Businesses USA, Health Permit Regulations USA, Health Permit Processing USA" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Health Permit for Businesses USA</strong></h1>
                <p className='text-white py-3 ppp'>Need a Health Permit for your business in the USA? SBA Tax Consultants offer expert guidance to navigate the permit process smoothly and efficiently. Get started with us today!</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Streamlined Health Permit Services with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Navigating health permit requirements in the USA can be challenging. At SBA Tax Consultants, we simplify the process for you. Whether you need assistance with the USA health permit application or understanding health permit regulations, our expert team is here to guide you. We handle all aspects of health permit processing, ensuring compliance with local and national standards.</p>
            <p>Trust us to streamline your health permit for businesses in the USA, making your experience stress-free and efficient.</p>
            
            
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default HPBU;