import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

import { Link } from 'react-router-dom';
import SliderImg from './slider-1.webp';
import { useNavigate } from 'react-router-dom';

export default function IndividualTaxFiling() {
    const [formData, setFormData] = useState({
      fname: '',
      lname: '',
      email: '',
      location: '',
      service: '',
      message: '',
      mobile: ''
    });
  
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate hook
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Perform validation here
      // Example: Check if required fields are filled, validate email format, etc.
  
      // Example: Sending email using EmailJS
      emailjs.sendForm('service_d2cjn1n', 'template_tt76ylp', e.target, 'YIIaEB3NYc5K8grKq')
        .then((result) => {
          console.log(result.text);
          setIsSubmitted(true);
        }, (error) => {
          console.log(error.text);
          // Optionally, show an error message to the user
        });
  
      // Clear form after submission
      setFormData({
        fname: '',
        lname: '',
        email: '',
        location: '',
        service: '',
        message: '',
        mobile: ''
      });
    };
  
    // Redirect to thank you page if form is successfully submitted
    if (isSubmitted) {
        window.location.href = 'https://sbataxconsultants.com/thank-you';
        return null; // Return null to prevent rendering the component after redirection
    }

  return (
    <>
        <section className='firstttSec'>
            <img src={SliderImg} className='img-fluid' alt="img" />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="bannerText">
                            <h3 className='heading alert-text mb-4 lh-base'>Last date to file Income Tax Return (ITR) for Financial Year 2023-24 (Assessment Year 2024-25) without late fees is 31st July 2024.</h3>
                            {/* <p className='fw-normal m-none mb-4'>A Taxpayer who does not pay the amount of tax due by April 15th may incur late-payment penalties and will be charged interest at the statutory rate.</p> */}
                            <a class="border border-2 mt-5  mtt-2 p-2 text-white text-decoration-none h5" href="#ApplyNow"><span className=''>Apply Now</span></a>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>

        <section className='py-5 bg-dark' id='ApplyNow'>
            <div className="container">
                <div className="row justify-content-center mob-cr">
                    <div className="col-md-5">
                        <h2 className='pb-4 secondary-text'>Connect with us</h2>
                        <h4 className="h4 text-white mb-4">How can we help?</h4>

                        <form onSubmit={handleSubmit} className='landingPageForm'>
                            <div className="form-group">
                            <input type="text" placeholder='First Name' name='fname' required minLength="5" maxLength="50" className="form-control mb-4" 
                                value={formData.fname}  onChange={handleChange}  onKeyDown={(e) => {
                                    // Allow only alphabets and space
                                    if (!/^[A-Za-z\s]*$/.test(e.key)) {
                                    e.preventDefault();
                                    }
                                }}
                                />

                            <input  type="text"  placeholder='Last Name' name="lname" required minLength="2" maxLength="50" className="form-control mb-4" 
                                value={formData.lname} onChange={handleChange} onKeyDown={(e) => {
                                    // Allow only alphabets and space
                                    if (!/^[A-Za-z\s]*$/.test(e.key)) {
                                    e.preventDefault();
                                    }
                                }}
                                />

                                <input type="email" placeholder='Email' name='email' required minLength="5" maxLength="50" className="form-control mb-4" />
                                <input type="text" placeholder='Mobile' name='mobile' required minLength="10" maxLength="13" className="form-control mb-4" value={formData.mobile} onChange={handleChange}
                                    onKeyDown={(e) => {
                                        // Allow only numbers (0-9), backspace, delete, and arrow keys
                                        if (!/^\d$/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(e.key)) {
                                        e.preventDefault();
                                        }
                                    }}
                                    />

                                <select className='form-control mb-4' required name="location">
                                    <option value="" disabled selected>Select Location</option>                                    
                                    <option value="Alabama">Alabama</option>
                                    <option value="Alaska">Alaska</option>
                                    <option value="Arizona">Arizona</option>
                                    <option value="Arkansas">Arkansas</option>
                                    <option value="California">California</option>
                                    <option value="Colorado">Colorado</option>
                                    <option value="Connecticut">Connecticut</option>
                                    <option value="Delaware">Delaware</option>
                                    <option value="Florida">Florida</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Idaho">Idaho</option>
                                    <option value="Illinois">Illinois</option>
                                    <option value="Indiana">Indiana</option>
                                    <option value="Iowa">Iowa</option>
                                    <option value="Kansas">Kansas</option>
                                    <option value="Kentucky">Kentucky</option>
                                    <option value="Louisiana">Louisiana</option>
                                    <option value="Maine">Maine</option>
                                    <option value="Maryland">Maryland</option>
                                    <option value="Massachusetts">Massachusetts</option>
                                    <option value="Michigan">Michigan</option>
                                    <option value="Minnesota">Minnesota</option>
                                    <option value="Mississippi">Mississippi</option>
                                    <option value="Missouri">Missouri</option>
                                    <option value="Montana">Montana</option>
                                    <option value="Nebraska">Nebraska</option>
                                    <option value="Nevada">Nevada</option>
                                    <option value="New Hampshire">New Hampshire</option>
                                    <option value="New Jersey">New Jersey</option>
                                    <option value="New Mexico">New Mexico</option>
                                    <option value="New York">New York</option>
                                    <option value="North Carolina">North Carolina</option>
                                    <option value="North Dakota">North Dakota</option>
                                    <option value="Ohio">Ohio</option>
                                    <option value="Oklahoma">Oklahoma</option>
                                    <option value="Oregon">Oregon</option>
                                    <option value="Pennsylvania">Pennsylvania</option>
                                    <option value="Rhode Island">Rhode Island</option>
                                    <option value="South Carolina">South Carolina</option>
                                    <option value="South Dakota">South Dakota</option>
                                    <option value="Tennessee">Tennessee</option>
                                    <option value="Texas">Texas</option>
                                    <option value="Utah">Utah</option>
                                    <option value="Vermont">Vermont</option>
                                    <option value="Virginia">Virginia</option>
                                    <option value="Washington">Washington</option>
                                    <option value="West Virginia">West Virginia</option>
                                    <option value="Wisconsin">Wisconsin</option>
                                    <option value="Wyoming">Wyoming</option>
                                </select>

                                <select name="service" required className='form-control mb-4'>
                                    <option value="" disabled selected>Select Type of Services</option>   
                                    <option value="401K Assistance">401K Assistance </option>   
                                    <option value="Accounting">Accounting </option>   
                                    <option value="Bookkeeping & Business Consultancy">Bookkeeping & Business Consultancy </option>   
                                    <option value="Corporation Registrations">Corporation Registrations </option>   
                                    <option value="Individual Tax">Individual Tax </option>   
                                    <option value="Insurance">Insurance </option>   
                                    <option value="ITIN/IRS Related works">ITIN/IRS Related works </option>   
                                    <option value="Licenses">Licenses </option>   
                                    <option value="NRI Services">NRI Services </option>   
                                    <option value="Notary Solutions">Notary Solutions </option>   
                                    <option value="Payroll Services">Payroll Services </option>   
                                    <option value="Sales Tax">Sales Tax </option>   
                                    <option value="">Tax Consultations </option>   
                                    <option value="">Tax Filing </option> 
                                </select>

                                
                                <textarea type="text" name="message" placeholder='Message' required minLength="5" maxLength="50" className="form-control mb-4" value={formData.message} onChange={(e) => { const value = e.target.value; if (/^[a-zA-Z,\.\s]*$/.test(value) || value === '') { setFormData({ ...formData, message: value }); } }} />

                                <button className='btn btn-dark' type='submit'>Submit</button>

                            </div>
                        </form>
                    </div>

                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                        <h2 className='secondary-text'>What we do?</h2>
                        <p className='text-white'>SBA Tax Consultants Help offers company and individual income tax preparation services. By identifying credits and deductions you are eligible for, a skilled tax preparer may help you save money and ensure that you are in compliance with both state and federal tax laws.</p>
                        <h4 className='secondary-text'>Our Services</h4>
                        <ul className='serviceName'>                        
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/401k-assistance">&#10148;&nbsp;&nbsp;401K Assistance</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/accounting">&#10148;&nbsp;&nbsp;Accounting</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/bookkeeping-and-business-consulting">&#10148;&nbsp;&nbsp;Bookkeeping & Business Consultancy</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/corporate-registration">&#10148;&nbsp;&nbsp;Corporation Registrations</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/insurance">&#10148;&nbsp;&nbsp;Insurance</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/irs-related-works">&#10148;&nbsp;&nbsp;ITIN/IRS Related works</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/license">&#10148;&nbsp;&nbsp;Licenses</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/nri-services">&#10148;&nbsp;&nbsp;NRI Services</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/notary-solutions">&#10148;&nbsp;&nbsp;Notary Solutions</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/payroll-services">&#10148;&nbsp;&nbsp;Payroll Services</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/sales-tax">&#10148;&nbsp;&nbsp;Sales Tax</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/tax-consultation">&#10148;&nbsp;&nbsp;Tax Consultations</Link></li>
                            <li className='w-fitcontent'><Link className='text-decoration-none' to="https://sbataxconsultants.com/tax-filing">&#10148;&nbsp;&nbsp;Tax Filing</Link></li>
                        </ul>

                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
