import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/TCEQ-Regulation-in-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const TCEQ = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>TCEQ Compliance & Regulations USA | SBA Tax Consultants</title>
        <meta name="description" content="Expert TCEQ compliance and environmental standards with SBA Tax Consultants. Navigate TCEQ guidelines and regulations efficiently." />
        <meta name="keywords" content="TCEQ Compliance, TCEQ Regulations USA, Environmental Compliance TCEQ, TCEQ Guidelines, TCEQ Environmental Standards" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Texas Commission on Environmental Quality Regulation in USA</strong></h1>
                <p className='text-white py-3 ppp'>Navigate Texas Commission on Environmental Quality regulations with ease. SBA Tax Consultants offers expert guidance for compliance and licensing. Get professional support today!</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>TCEQ Compliance and Environmental Standards</b></h2>
            <p>At SBA Tax Consultants, we ensure your business meets all TCEQ compliance requirements with ease. Our expert team navigates TCEQ regulations in the USA, helping you adhere to environmental compliance standards and guidelines.</p>
            <p>Trust us to manage your TCEQ environmental standards efficiently, so you can focus on what you do best.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default TCEQ;
