import React from 'react'
import topImage from '../img/terms-of-service.jpg'
import {Helmet} from "react-helmet";

export default function Terms() {
  return (
    <>
    <Helmet>
        <title>Terms of Service Regarding Tax Advice and Business Terms</title>
        <meta name="description" content="At SBA, we are constantly striving for excellence for our clients. You agree to be covered by all of the information made." />
        <meta name="keywords" content="Tax Planning, Taxable Income, NRI Tax Services, IRS Tax Services" />
    </Helmet>
        <section>
            <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
            <div className="container">            
                <div className='bannerText'>
                    <h1>Terms of Service </h1>
                    <p className=''>"Explore our terms of service for comprehensive tax guidance. Transparent, compliant, and client-focused to ensure mutual success and satisfaction."</p>
                </div>            
            </div>
            
        </section>
        <section className='py-5'>
            <div className="container py-3">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className='text-center'>Updating Soon</h2>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
