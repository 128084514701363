import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/msb-license-in-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const MSB = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>MSB License USA - SBA Tax Consultants | Compliance & Registration</title>
        <meta name="description" content="Get your MSB License in the USA with SBA Tax Consultants. Expert help for MSB Registration, Compliance, and more." />
        <meta name="keywords" content="MSB License USA, Money Services Business License USA, MSB Registration USA, Obtain MSB License USA, MSB Compliance USA" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Money Services Business License USA</strong></h1>
                <p className='text-white py-3 ppp'>Looking to get a Money Services Business License in the USA? SBA Tax Consultants offers expert guidance and support to navigate the licensing process smoothly. Get started today!</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>MSB License USA: Your Path to Compliance with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Navigating the world of money services business (MSB) licenses can be complex, but SBA Tax Consultants make it simple. Whether you're looking to obtain an MSB license in the USA or need MSB registration in the USA, our expert team is here to guide you through every step. We ensure your business meets all MSB compliance requirements in the USA, so you can operate with confidence. </p>
            <p>Trust SBA Tax Consultants to streamline your money services business license process and keep you compliant.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default MSB;