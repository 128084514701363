import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/Sales-Tax-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const SalesTaxSolutionUSA = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>Sales Tax Services & Compliance in USA | SBA Tax Consultants</title>
        <meta name="description" content="Expert Sales Tax Services USA. Ensure compliance & smooth filing with SBA Tax Consultants' tailored solutions." />
        <meta name="keywords" content="Sales Tax USA, Sales Tax Services USA, Sales Tax Compliance USA, Sales Tax Filing USA, Sales Tax Solutions USA" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Sales Tax Solutions USA</strong></h1>
                <p className='text-white py-3 ppp'>Looking for efficient sales tax solutions in the USA? SBA Tax Consultants provide expert assistance to streamline your sales tax processes and ensure compliance. Get in touch today!</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Sales Tax Services with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Navigating sales tax in the USA can be complex, but SBA Tax Consultants are here to simplify the process. Our comprehensive Sales Tax Services USA ensure you stay compliant with all regulations, offering expert solutions for Sales Tax Compliance USA. From accurate Sales Tax Filing USA to tailored Sales Tax Solutions USA, we handle every detail to keep your business running smoothly.</p>
            <p>Trust us to manage your sales tax needs with professionalism and precision.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default SalesTaxSolutionUSA;
