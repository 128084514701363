import React from 'react'

import Img from '../img/home-page-banner-1.webp'

export default function SliderSec() {
  return (
    <section className='bg-video'>
        
        {/* <video autoPlay muted="muted" loop poster="" className="video">
            <source src={video} type="video/mp4" className='video' />
        </video> */}
        <img src={Img} className='img-fluid' alt='Banner Img' />
        <div className='SliderText'>
            <h1 className='border-bottom border-2 mb-3 pb-3'>Welcome to <br></br><span className='fw-bold primary-text'>SBA Tax Consultants</span></h1>
            <h4 className='fw-bold mb-5'>With our knowledgeable staff and commitment to excellence, we strive to provide top-notch service to individuals and businesses alike.</h4>
            <a className='btn btn-danger' href="tel:+1 469 722 5480"><span className='h4 primary-text px-4'><i className="bi bi-telephone-forward"></i> Call Us </span></a>&nbsp;&nbsp;&nbsp;<a className='btn btn-danger' href="./contact-us"><span className='h4 px-2 primary-text'>Get in Touch</span></a>
        </div>
        
        {/* <div id="demo" className='container d-none'>
            <div className="col-md-12 position-relative">                
                <div className='SliderText'>
                    
                    <p className=''>With our knowledgeable staff and commitment to excellence, we strive to provide top-notch service to individuals and businesses alike.</p>
                    <a href="#contactUs"><button className='btn btn-success px-5 py-3'>Get In Touch ! <i className="bi bi-envelope"></i></button></a>
                </div>
            </div>
        </div>  */}
      
    </section>
  )
}
