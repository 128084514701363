import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/food-stamps-application-Texas.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const FSAT = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>MSB License USA - SBA Tax Consultants | Compliance & Registration</title>
        <meta name="description" content="Get your MSB License in the USA with SBA Tax Consultants. Expert help for MSB Registration, Compliance, and more." />
        <meta name="keywords" content="MSB License USA, Money Services Business License USA, MSB Registration USA, Obtain MSB License USA, MSB Compliance USA" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Food Stamps Application Texas</strong></h1>
                <p className='text-white py-3 ppp'>Apply for Food Stamps in Texas with ease. SBA Tax Consultants provide expert assistance with your Texas SNAP application to ensure you get the support you need.</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Texas SNAP Application Made Easy with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Navigating the Texas SNAP Application process can be straightforward with the right support. At SBA Tax Consultants, we assist you in applying for food stamps in Texas, ensuring you understand every step of the Texas Food Stamp Program.</p>
            <p>Our expertise in food assistance in Texas means we’re here to streamline your Texas EBT application, providing you with the help you need to access the benefits you deserve. Let us make the process smooth and stress-free.</p>
            
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default FSAT;