import React, { useState, useEffect } from 'react';
import Inc_Sticky_Form from '../Inc_Sticky_Form';
import topImage from '../img/accounting/Accounting.jpg'
import {Helmet} from "react-helmet";
export default function Accounting() {
    const [styles, setStyles] = useState({
        position: 'static',
        top: '0px',
        width: '100%',
        visibility: 'visible'
      });
    
      useEffect(() => {
        const handleScroll = () => {
          const mq = window.matchMedia("(min-width: 768px)");
    
          if (mq.matches) {
            const sTop = window.scrollY;
    
            if (sTop > 400 && sTop <= 1200) {
              setStyles({
                position: 'fixed',
                top: '150px',
                width: '30%',
                right:'3%',
                visibility: 'visible'            
              });
            }
    
            if (sTop >1200) {
              setStyles({
                position: 'static',
                top: '150px',
                width: '23%',
                visibility: 'hidden'
              });
            }
    
            if (sTop < 350) {
              setStyles({
                position: 'static',
                top: '0px',
                width: '100%',
                visibility: 'visible'
              });
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <>
    <Helmet>
        <title>Tax Consultant and Accounting Services in USA</title>
        <meta name="description" content="Get professional accounting services for your small business from SBA Tax Consultants. Our expert accountant services ensure your financial success." />
        <meta name="keywords" content="accounting service, business accounting services, accountant services for small business, Accounting for Business.
" />
    </Helmet>
    <section>
          <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
          {/* <div className="container">            
              <div className='bannerText'>
                  <h1>Accounting</h1>
                  <p className=''>We in the USA often recommend various accounting solutions and software to their clients based on the specific needs, size, and industry of the business. </p>
              </div>            
          </div> */}
          
    </section>
    
    <section>
      <div className="container py-5">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='h2 primary-text'>Accounting Services</h1>
                <h6 className=''>We often recommend various accounting solutions and software to our clients based on the specific needs, size, and industry of the business. </h6>
               
                <h5 className='py-2 primary-text'>Some commonly suggested accounting software include:</h5>
                
           
                <div className="border-bottom">
                    <h5 className='primary-text'>QuickBooks:</h5>   
                    <p>QuickBooks is a widely used accounting software suitable for small to medium-sized businesses. It offers features for invoicing, expense tracking, payroll processing, financial reporting, and inventory management. We often recommend QuickBooks due to its user-friendly interface and extensive capabilities.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Xero:</h5>   
                    <p>Xero is another cloud-based accounting software popular among small businesses. It provides features for bank reconciliations, invoicing, expense tracking, payroll, and integrates with various third-party apps. We may recommend Xero for its accessibility and collaboration features.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>FreshBooks:</h5>   
                    <p>FreshBooks is known for its user-friendly interface and is popular among freelancers and small service-based businesses. It offers invoicing, time tracking, expense management, and basic accounting functionalities.</p>
                </div>
        
                <div className="border-bottom">
                    <h5 className='primary-text'>Sage Intacct:</h5>   
                    <p>Sage Intacct is a cloud-based accounting software designed for medium to large businesses. It offers robust financial management features, including multi-entity support, advanced reporting, budgeting, and analytics. We may suggest Sage Intacct for its scalability and advanced capabilities.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>NetSuite:</h5>   
                    <p>NetSuite, now a part of Oracle, is an enterprise-level ERP (Enterprise Resource Planning) software. It offers comprehensive financial management, CRM, inventory, and e-commerce functionalities. We might recommend NetSuite for larger businesses needing integrated solutions across various departments.</p>
                </div>
        
                <div className="">
                    <p className=''>We are often well-versed in these accounting solutions as they regularly work with different businesses and industries. They understand the features, functionalities, and suitability of these software options based on a company's specific accounting needs.</p>                
                    <p>We can recommend accounting software after assessing factors like the complexity of accounting needs, integration requirements with other systems, industry-specific functionalities, scalability, budget, and user-friendliness. They might also consider the client's preferences, level of accounting expertise, and the need for additional features like inventory management or project tracking.</p>
                    <p>Additionally, We can assist in the implementation, setup, and customization of the recommended software, ensuring it aligns with the business's accounting processes and provides accurate financial information for decision-making and compliance.</p>
                </div>
            </div>
            <div className="col-md-4 fixedElement" style={styles}>
                <Inc_Sticky_Form />
            </div>
        </div>
        
      </div>
    </section>
    </>
  )
}
