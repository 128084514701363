import React, { useState, useEffect } from 'react';
import Inc_Sticky_Form from '../Inc_Sticky_Form';
import topImage from '../img/taxConsultation/Tax-Consultation.jpg'
import {Helmet} from "react-helmet";
export default function TaxConsultation() {
    const [styles, setStyles] = useState({
        position: 'static',
        top: '0px',
        width: '100%',
        visibility: 'visible'
      });
    
      useEffect(() => {
        const handleScroll = () => {
          const mq = window.matchMedia("(min-width: 768px)");
    
          if (mq.matches) {
            const sTop = window.scrollY;
    
            if (sTop > 400 && sTop <= 1600) {
              setStyles({
                position: 'fixed',
                top: '150px',
                width: '30%',
                right:'3%',
                visibility: 'visible'            
              });
            }
    
            if (sTop >1600) {
              setStyles({
                position: 'static',
                top: '150px',
                width: '23%',
                visibility: 'hidden'
              });
            }
    
            if (sTop < 350) {
              setStyles({
                position: 'static',
                top: '0px',
                width: '100%',
                visibility: 'visible'
              });
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <>
    <Helmet>
        <title>Tax Experts and Tax Consultants  in USA</title>
        <meta name="description" content="Trust SBA Tax Consultants, your US Tax Expert. Our tax consulting services offer top tax consultation by leading tax consultants in the USA." />
        <meta name="keywords" content="us tax expert, tax consultant in usa, us tax consultant, Tax consulting, tax consultation online, Tax consultant usa." />
    </Helmet>
    <section>
          <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
          <div className="container">            
              {/* <div className='bannerText'>
                  <h1>Tax Consultation </h1>
                  <p className=''>We in the USA offer comprehensive tax consultation services to individuals, businesses, and organizations.</p>
              </div>             */}
          </div>
          
    </section>
    
    <section>
      <div className="container py-5">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='h2 primary-text'>Tax consultant in USA</h1>
                <h6 className=''>We offer comprehensive tax consultation services to individuals, businesses, and organizations. These consultations encompass a wide range of tax-related matters and provide guidance, strategies, and solutions tailored to each client's specific needs.</h6>
               
                <h5 className='py-2 primary-text'>Here's an overview of the tax consultation services provided by We:</h5>              
                
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Tax Planning and Strategy:</h5>   
                    <p>We provide strategic tax planning services aimed at minimizing tax liabilities while ensuring compliance with tax laws. They assess individual or business financial situations, identify tax-saving opportunities, and create customized plans for efficient tax management.
                    We provide strategic tax planning services aimed at minimizing tax liabilities while ensuring compliance with tax laws. They assess individual or business financial situations, identify tax-saving opportunities, and create customized plans for efficient tax management.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Entity Structure and Tax Optimization:</h5>   
                    <p>
                    We advise businesses on the most advantageous entity structures (such as sole proprietorship, partnership, corporation, or LLC) considering tax implications. They assess the impact of different structures on taxation, liability, and operational needs.

                    </p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Retirement and Estate Planning:</h5>   
                    

                    <p>
                    We offer guidance on tax-efficient retirement planning strategies, including contributions to retirement accounts (like IRAs, 401(k)s), managing distributions, and minimizing taxes during retirement. They also assist with estate planning to minimize estate taxes and facilitate smooth wealth transfer.
                    </p>
                </div>
        
                <div className="border-bottom">
                    <h5 className='primary-text'>Tax Implications of Investments:</h5>   
                    <p>
                    We analyze the tax implications of various investment options, including stocks, bonds, real estate, and other financial instruments. They advise on investment strategies that optimize tax outcomes.
                    </p>
                </div>
        
                <div className="border-bottom">
                    <h5 className='primary-text'>Business Transactions and Mergers:</h5>   
                    <p>
                    We provide tax advice during business transactions, mergers, acquisitions, or sales. They evaluate the tax consequences of these transactions, structure deals to minimize tax liabilities, and ensure compliance with tax laws.
                    </p>
                </div>
       
                <div className="border-bottom">
                    <h5 className='primary-text'>
                    Tax Credits and Incentives:</h5>   
                    <p>
                    We identify and advise clients on available tax credits, deductions, and incentives applicable to their specific circumstances. They help businesses take advantage of tax credits related to research and development, energy efficiency, hiring incentives, etc.
                    </p>
                </div>
       
                <div className="border-bottom">
                    <h5 className='primary-text'>IRS Representation and Compliance</h5>   
                    <p>
                    We represent clients in dealings with the IRS, providing support during audits, examinations, appeals, and collections. They ensure clients' compliance with tax laws and assist in resolving tax-related issues.
                    </p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Tax Compliance Reviews and Strategies:</h5>   
                    <p>We conduct reviews of tax compliance procedures for individuals and businesses, ensuring adherence to tax laws and identifying areas for improvement. They develop strategies to enhance compliance and minimize potential tax risks.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Multi-State and International Taxation:</h5>   
                    <p>
                    We offer guidance on tax implications for businesses operating in multiple states or internationally. They navigate complex tax regulations across different jurisdictions, addressing issues related to nexus, withholding, and reporting requirements.
                    </p>
                </div>
        
                <div className="">                
                    <p>We leverage their expertise and in-depth knowledge of tax laws to provide valuable advice, proactive planning, and solutions that align with their clients' financial goals while optimizing tax efficiency and compliance.</p>
                </div>
            </div>
            <div className="col-md-4 fixedElement" style={styles}>
                <Inc_Sticky_Form />
            </div>
        </div>
      </div>
    </section>
    </>
  )
}
