import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import epsiu from '../img/icons/Expert-Permit-Services-in-the-USA.png'
import aacciu from '../img/icons/Amendments-and-Correction-Charges-in-USA.png'
import eancitu from '../img/icons/Establishing-a-New-Corporation-in-the-USA.png'
import dbaregis from '../img/icons/DBA-Registration-with-Corporation-USA.png'
import secondDBA from '../img/icons/DBA-without-Corporation-in-USA.png'
import salesTaxUSA from '../img/icons/Sales-Tax-USA.png'
import tobacoliu from '../img/icons/Tobacco-Licenses-in-USA.png'
import tabcliu from '../img/icons/Texas-Alcoholic-Beverage-Commission.png'
import tceq from '../img/icons/TCEQ-Regulation-in-USA.png'
import WMCIU from '../img/icons/weight-measurement-certificate-USA.png'
import MSB from '../img/icons/msb-license-in-USA.png'
import FSAT from '../img/icons/food-stamps-application-Texas.png'
import FPLIU from '../img/icons/food-permit-license-USA.png'
import OCIU from '../img/icons/Occupancy-Certificate-USA.png'
import HPIU from '../img/icons/Health-Permit-in-USA.png'
import LBL from '../img/icons/Lottery-Business-License.png'





const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-next" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-prev" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 2000,
    autoplay:true,
    slidesToShow: 4, // Number of items to show at a time
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

export default function ServiceOffered() {
  return (
    <section className='py-5 service_offered'>
      <div className="container">
        <div className="row justify-content-center pb-4">
          <div className="col-md-6">
            <h2 className='text-center h1'><b>We Offer Services That Work</b></h2>
            <p className='text-center'>“At SBA Tax Consultants, we provide a comprehensive suite of services tailored to meet your business and personal needs”
            </p>
          </div>
        </div>

        <Slider {...settings}>
                    
            <div className='p-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/types-of-permit-in-usa">
                    <img src={epsiu} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Permit Services in the USA</h5>
                    <p className='text-dark'>Streamline your business operations with our expert permit services in the USA, ensuring compliance and efficiency.</p>
                    </Link>
                </div>
            </div>
            <div className='p-2 mx-2 '>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/admendments-and-correction-charges-in-usa">
                    <img src={aacciu} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Amendments and Correction Charges in USA</h5>
                    <p className='text-dark'>Explore our streamlined process for Amendments and Correction Charges in the USA—ensuring accurate and compliant updates with minimal hassle.</p>
                    </Link>
                </div>
            </div>
            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/new-corporation-in-the-usa">
                    <img src={eancitu} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Establishing a New Corporation in the USA</h5>
                    <p className='text-dark'>Streamline the process of establishing a new corporation in the USA with our expert guidance and comprehensive services.</p>
                    </Link>
                </div>
            </div>
            
            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/first-dba-filing-with-corporation-usa">
                    <img src={dbaregis} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>DBA Registration with Corporation USA</h5>
                    <p className='text-dark'>Streamline your business operations with DBA registration under Corporation USA for enhanced professionalism and compliance.</p>
                    </Link>
                </div>
            </div>

            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/second-dba-registration-usa">
                    <img src={secondDBA} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>2nd DBA or DBA without Corporation in USA</h5>
                    <p className='text-dark'>Get your 2nd DBA or DBA without a corporation in the USA—simplify your business structure and enhance your operations effortlessly</p>
                    </Link>
                </div>
            </div>

            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/sales-tax-solution-usa">
                    <img src={salesTaxUSA} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Sales Tax USA</h5>
                    <p className='text-dark'>Navigate the complexities of Sales Tax in the USA with expert guidance and streamlined solutions.</p>
                    </Link>
                </div>
            </div>

            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/tobacco-licenses-in-usa">
                    <img src={tobacoliu} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Tobacco Licenses in USA</h5>
                    <p className='text-dark'>Navigate the complexities of obtaining tobacco licenses in the USA with expert guidance and support</p>
                    </Link>
                </div>
            </div>

            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/lottery-business-license-usa">
                    <img src={LBL} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Lottery Business License USA</h5>
                    <p className='text-dark'>Get your USA Lottery Business License with expert guidance from SBA Tax Consultants.</p>
                    </Link>
                </div>
            </div>
        </Slider>

        {/* Another Row */}

        <Slider {...settings}>
                    
            <div className='p-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/texas-alcoholic-beverage-commission-license-in-usa">
                    <img src={tabcliu} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>TABC license in usa</h5>
                    <p className='text-dark'>Secure your TABC license with ease—essential for Texas alcohol beverage businesses.</p>
                    </Link>
                </div>
            </div>
            <div className='p-2 mx-2 '>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/texas-commission-on-environmental-quality-regulation-in-usa">
                    <img src={tceq} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>TCEQ Regulation in USA</h5>
                    <p className='text-dark'>Stay compliant with TCEQ regulations—expert guidance for all your environmental needs.</p>
                    </Link>
                </div>
            </div>
            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/weight-and-measurement-certificate-usa">
                    <img src={WMCIU} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Weight & Measurement Certificate USA</h5>
                    <p className='text-dark'>Get accurate and certified weight & measurement services for seamless compliance in the USA.</p>
                    </Link>
                </div>
            </div>
            
            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/money-services-business-license-usa">
                    <img src={MSB} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>MSB license in USA</h5>
                    <p className='text-dark'>Obtain your Money Services Business license with expert guidance for smooth regulatory compliance.</p>
                    </Link>
                </div>
            </div>

            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/food-stamps-application-texas">
                    <img src={FSAT} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Food Stamps Application Texas</h5>
                    <p className='text-dark'>Easily apply for food stamps in Texas with our streamlined application process.</p>
                    </Link>
                </div>
            </div>

            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/food-permit-license-usa">
                    <img src={FPLIU} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Food Permit License USA</h5>
                    <p className='text-dark'>Navigate the complexities of Sales Tax in the USA with expert guidance and streamlined solutions.</p>
                    </Link>
                </div>
            </div>

            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/health-permit-for-businesses-usa">
                    <img src={HPIU} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Health Permit in USA</h5>
                    <p className='text-dark'>Navigate the complexities of obtaining tobacco licenses in the USA with expert guidance and support</p>
                    </Link>
                </div>
            </div>

            <div className='p-2 mx-2'>
                <div className="service-Box">
                    <Link to="https://sbataxconsultants.com/occupancy-certificate-usa-requirements">
                    <img src={OCIU} className='img-fluid '  alt="service" />
                    <h5 className='text-dark'>Occupancy Certificate USA</h5>
                    <p className='text-dark'>Ensure your property is legally fit for use with our hassle-free certification services.</p>
                    </Link>
                </div>
            </div>
        </Slider>
        
      </div>
    </section>
  )
}
