import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/why-chooose-us.jpg'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const TypesOfPermitInUSA = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>Get Your Types of Permit in USA | SBA Tax Consultants</title>
        <meta name="description" content="Need a building permit in the USA? SBA Tax Consultants make permit applications easy for construction, business, and work permits." />
        <meta name="keywords" content="Building Permit USA, Permit Application USA, Construction Permit USA, Business Permit USA, Work Permit USA" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Expert Permit Services in the USA</strong></h1>
                <p className='text-white py-3 ppp'>Get hassle-free permit approvals with our expert services. We handle all permit types across the USA, ensuring quick and compliant processing. Trust our expertise for smooth and efficient results.</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Streamlined Permit Services with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>At SBA Tax Consultants, we simplify your permit needs with expert assistance in various areas: Building Permits USA, Permit Applications USA, Construction Permits USA, Business Permits USA, and Work Permits USA. Our dedicated team ensures that all your permits are processed smoothly and efficiently, so you can focus on what matters most. </p>
            <p>Trust us for professional support and hassle-free permit solutions.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default TypesOfPermitInUSA;