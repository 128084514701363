import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/Lottery-Business-License-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const LBLU = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>Lottery Business License USA | SBA Tax Consultants</title>
        <meta name="description" content="Get your Lottery Business License in the USA with SBA Tax Consultants. Expert help for all licensing requirements and applications." />
        <meta name="keywords" content="Lottery Business License USA, How to Get a Lottery License in USA, Lottery License Application USA, USA Lottery Business Licensing Requirements, Lottery Business Permit USA" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Lottery Business License USA</strong></h1>
                <p className='text-white py-3 ppp'>Looking to secure a Lottery Business License in the USA? SBA Tax Consultants offers expert assistance with licensing requirements and applications. Contact us for streamlined, efficient service.</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Lottery Business License USA: Simplified with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Are you looking to start a lottery business and wondering how to get a lottery license in the USA? SBA Tax Consultants is here to help! We specialize in guiding you through the lottery license application process in the USA, ensuring you meet all the USA lottery business licensing requirements. Our expert team will assist you in navigating the complexities of obtaining a lottery business permit in the USA, making the entire process smooth and stress-free.</p>
            <p>Trust SBA Tax Consultants to provide you with the expertise and support you need to get your lottery business up and running legally and efficiently.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default LBLU;