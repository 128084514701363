import React, { useState, useEffect } from 'react';
import Inc_Sticky_Form from '../Inc_Sticky_Form';
import topImage from '../img/license/Licences.jpg'
import {Helmet} from "react-helmet";
export default function Licenses() {
    const [styles, setStyles] = useState({
        position: 'static',
        top: '0px',
        width: '100%',
        visibility: 'visible'
      });
    
      useEffect(() => {
        const handleScroll = () => {
          const mq = window.matchMedia("(min-width: 768px)");
    
          if (mq.matches) {
            const sTop = window.scrollY;
    
            if (sTop > 400 && sTop <= 1800) {
              setStyles({
                position: 'fixed',
                top: '150px',
                width: '30%',
                right:'3%',
                visibility: 'visible'            
              });
            }
    
            if (sTop >1800) {
              setStyles({
                position: 'static',
                top: '150px',
                width: '23%',
                visibility: 'hidden'
              });
            }
    
            if (sTop < 350) {
              setStyles({
                position: 'static',
                top: '0px',
                width: '100%',
                visibility: 'visible'
              });
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <>
    <Helmet>
        <title>Payroll Services | Payroll Providers for small businesses</title>
        <meta name="description" content="Discover payroll services in USA with SBA Tax Consultants. We're your trusted payroll service providers, specializing in solutions for small businesses" />
        <meta name="keywords" content="payroll providers for small businesses, best payroll for small business, payroll service providers, Payroll Tax Services, Payroll Services With Tax Filing" />
    </Helmet>
    <section>
          <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
          <div className="container">            
              {/* <div className='bannerText'>
                  <h1>Licenses </h1>
                  <p className=''>We at SBA Tax Consultants, provides services related to Licenses registration and related other compliance.</p>
              </div>             */}
          </div>
          
    </section>
    
    <section>
      <div className="container py-5">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='h2 primary-text'>Business License Services</h1>
                <h4 className='py-3'>
                The licenses and permits required for corporations in the USA vary widely based on the nature of the business, the industry, and the location of operations.

                </h4>
               
                <h4 className='py-5 primary-text'>Here are some common types of licenses and permits that corporations might need:</h4>              
                
            
            <div className="border-bottom">
                <h5 className='primary-text'>Employer Identification Number (EIN):</h5>
                <p>While not a license or permit per se, an EIN is required for most businesses to hire employees, open bank accounts, and file taxes. It's obtained from the IRS.</p>
            </div>
       
            <div className="border-bottom">
                <h5 className='primary-text'>Business License:</h5>   
                <p>Most businesses need a general business license to operate legally. The requirements for this license can vary by state, county, and city.</p>
                
            </div>
       
            <div className="border-bottom">
                <h5 className='primary-text'>Professional Licenses:</h5>
                <p>Certain professions, such as doctors, lawyers, accountants, architects, and others, require specific professional licenses issued by state licensing boards.</p>
            </div>
       
            <div className="border-bottom">
                <h5 className='primary-text'>Industry-Specific Licenses:</h5>
                <p>Various industries have their own set of licenses or permits. For instance, restaurants might need health permits, bars may require liquor licenses, construction companies might need building permits, and transportation companies might need permits for vehicles.</p>
            </div>
        
            <div className="border-bottom">
                <h5 className='primary-text'>Sales Tax Permit:</h5>
                <p>Businesses selling goods or services subject to sales tax typically need a sales tax permit to collect and remit sales tax to the state.</p>
            </div>
        
            <div className="border-bottom">
                <h5 className='primary-text'>Federal Business Licenses and Permits:</h5>
                <p>Some industries are regulated by federal agencies, requiring specific federal licenses or permits. For instance, firearms dealers need a federal firearms license (FFL), transportation companies may need Department of Transportation (DOT) permits, and financial institutions need licenses from federal regulatory bodies like the SEC or FINRA.</p>
            </div>
        
            <div className="border-bottom">
                <h5 className='primary-text'>Environmental Permits:</h5>
                <p>Certain businesses dealing with environmental impact, such as manufacturing or waste disposal, may need permits related to environmental regulations and pollution control.</p>
            </div>
        
            <div className="border-bottom">
                <h5 className='primary-text'>Zoning Permits:</h5>
                <p>Corporations setting up physical locations might require zoning permits to ensure compliance with local zoning laws and land use regulations.</p>
            </div>
        
            <div className="border-bottom">
                <h5 className='primary-text'>Health and Safety Permits:</h5>
                <p>Businesses dealing with food service, childcare, healthcare, and other similar industries might need health and safety permits to ensure compliance with regulations related to cleanliness and safety standards.</p>
            </div>
        
            <div className="border-bottom">
                <h5 className='primary-text'>Intellectual Property Licenses:</h5>
                <p>Corporations dealing with intellectual property, such as patents, trademarks, or copyrights, might need specific licenses or registrations to protect their intellectual assets.</p>
            </div>
        
            <div className="">                
                <p>The requirements for licenses and permits can vary significantly depending on the nature of the business, its location, and the specific activities it conducts. It's crucial for corporations to research and comply with federal, state, and local regulations to ensure legal operation and avoid penalties or legal issues. Consulting with legal counsel or regulatory experts can help businesses navigate the complex landscape of licensing requirements.</p>
            </div>


        </div>
        <div className="col-md-4 fixedElement" style={styles}>
            <Inc_Sticky_Form />
        </div>

        

      </div>
      </div>
    </section>
    </>
    
  )
}
