import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/weight-measurement-certificate-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const WMC = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>Certified Weighing & Measurement Services | SBA Tax Consultants</title>
        <meta name="description" content="Get expert weight certification and measurement compliance in the USA with SBA Tax Consultants. Trusted US weight & measurement services." />
        <meta name="keywords" content="Weight Certification USA, Measurement Certificate USA, US Weight and Measurement Services, Certified Weighing and Measurement USA, Weight Measurement Compliance USA" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Weight & Measurement certificate USA</strong></h1>
                <p className='text-white py-3 ppp'>Looking for a Weight & Measurement certificate in the USA? SBA Tax Consultants offer expert assistance with certification requirements and processing. Contact us for reliable, efficient service.</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Certified Weighing and Measurement Services in the USA</b></h2>
            <p>At SBA Tax Consultants, we specialize in providing top-notch Weight Certification USA and Measurement Certificate USA services. Our team ensures that your business complies with Weight Measurement Compliance USA standards, offering reliable and accurate US Weight and Measurement Services.</p>
            <p>Trust our expertise for professional and certified weighing and measurement solutions tailored to your needs.
            </p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default WMC;